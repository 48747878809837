import {BehaviorSubject, } from 'rxjs';
import Axios from "axios";
import {Message} from "element-ui";

//import config from 'config';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
};

function login(username, password) {
    return Axios.post('api/Account/Authenticate', {
        Username: username, Password: password
    }).then((response) => {
        //console.log(response);
        if (response.data === "") {
            Message.error("მომხმარებელი ან პაროლი არასწორია.")
            return
        }
        
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        currentUserSubject.next(response.data);
        return response.data;
    })
        .catch((error) => {
            console.log(error);

            alert("ERROR: " + (error.message | error));
        });
    /*fetch(`${config.apiUrl}/users/authenticate`, requestOptions.post({ username, password }))
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });*/
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.reload();
}

export default authenticationService
// initial state
const state = () => ({
    caseId:0,
})

// getters
const getters = {
    
}


// actions
const actions = {
    
}

// mutations
const mutations = {
    setCaseId (state, id) {
        state.caseId = id
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
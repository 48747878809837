import Vue from 'vue'
import Vuex from 'vuex'
import Regress from './Modules/Claim/Regress'
import Calculate from './Modules/Calculation/Calculate'
import AddStaetment from './Modules/MedStatement/AddStatement'
import VitCalculate from './Modules/VitaminCalculation/Calculate'
import StatementsPrmissions from './Modules/MedStatement/Permission'



Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'



export default new Vuex.Store({
    modules: {
        Regress,
        Calculate,
        AddStaetment,
        VitCalculate,
        StatementsPrmissions

    },
    strict: debug,
    // state: {
        
    // },
 
   
})
// initial state
import store from "@/Store";

const state = () => ({
    statementOptions:[
            {value: 1, text: 'მიმართვა ოპერაციისთვის', IsBelongToClinic:true},
            {value: 2, text: 'მიმართვა მედიკამენტებისთვის',IsBelongToClinic:true},
            {value: 3, text: 'მიმართვა ამბოლატორიული მომსახურებისთვის',IsBelongToClinic:true},
            {value: 4, text: 'მიმართვა პროფილაქტიკური გამოკვლევებისთვის',IsBelongToClinic:true},
            {value: 5, text: 'MED ელექტრონული ანაზღაურება',IsBelongToClinic:false},
            {value: 6, text: 'TRP ელექტრონული ანაზღაურება',IsBelongToClinic:false},
        ],
    form: {
        id: "",
        name: "",
        polid: "",
        phone: "",
        StatementType: "",
        comment: "",
        dateOfBirth: "",
        assignToAssistant: false,
    },
    
})

// getters
const getters = {
    getStaementOptions: (state) =>(isClinic) => {
        if(isClinic)
            return state.statementOptions.filter(x=>x.IsBelongToClinic);
        return state.statementOptions;
    },
   
}


// actions
const actions = {

}

// mutations
const mutations = {
}
    
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
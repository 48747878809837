import moment from 'moment';
export default {
    data() {
      return {

      };
    },
    methods: {

        formatDate: function (value) {
          if (value) {
            return moment(value).format('yyyy-MM-DD')
          }
        }
      
    },
  };
const state = () => ({
        isVipManager:false,
        hasAccessToEdit:false
})

// getters
const getters = {

}


// actions
const actions = {

}

// mutations
const mutations = {

    setVipManagerStatus(state,status){
        state.isVipManager=status
    },
    setEditStatus(state,status){
        state.hasAccessToEdit=status
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
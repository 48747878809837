<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {authenticationService} from './Services/Authentication';

export default {
  name: 'app',
  created() {
    authenticationService.currentUser.subscribe(x => this.currentUser = x);
    const { userRole } = this.currentUser;
    
    if (userRole.includes('ClaimRegulation')) {
      // this.$socket.emit('join_in_managers', userRole);
    }
    
  },
  
  sockets: {
    connect: function () {
    },
    get_new_case: function (newCase) {
      this.$notify({
        title: newCase.claimid,
        message: `მენჯერმა დაასრულა მუშაობა განაცხადზე ნომრით: ${newCase.claimid} <br /> <a href="/claim/regulation"> რეგულირების გვერდზე გადასვლა </a>`,
        type: 'success',
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        duration: 0,
      });
    }
  },
  
}
</script>

<style lang="scss">
  @import "assets/style/main";
</style>

// initial state
const state = () => ({
    calcUrl: 'https://calculator.ardi.ge/api',
    activeStep: 0,
    currency: 'gel',
    currencyCodes: {
        GEL: '₾',
        EUR: '€',
        USD: '$'
    },
    calculatedPrices: {
        discount: 0,
        packageCode: '',
        packageName: '',
        packagePrice: 0,
        payPremiumPerMonth: 0,
        premium: 0,
        vipPremium: 0,
        vitaminPremium : 0
    },   
    CardCalculator: {},
})

// getters
const getters = {
    
}


// actions
const actions = {
    
}

// mutations
const mutations = {
    UpdateCalculatedPrices(state, prices) {
        state.calculatedPrices.discount = prices.discount;
        state.calculatedPrices.packageCode = prices.packageCode;
        state.calculatedPrices.packageName = prices.packageName;
        state.calculatedPrices.packagePrice = prices.packagePrice;
        state.calculatedPrices.payPremiumPerMonth=prices.payPremiumPerMonth;
        state.calculatedPrices.premium=prices.premium;
        state.calculatedPrices.vipPremium=prices.vipPremium;
        state.calculatedPrices.vitaminPremium=prices.vitaminPremium;       
    },
    resetCalculatedPrices(state){
        state.calculatedPrices.discount = 0;
        state.calculatedPrices.packageCode = '';
        state.calculatedPrices.packageName = '';
        state.calculatedPrices.packagePrice = 0;
        state.calculatedPrices.payPremiumPerMonth=0;
        state.calculatedPrices.premium=0;
        state.calculatedPrices.vipPremium=0;
        state.calculatedPrices.vitaminPremium=0;  
    },
    UpdateCardCalculator(state, card) {
        state.CardCalculator = card
    },
    updateActiveStep(state, id){
        state.activeStep = id  
    },
    updateCurrency(state, currency) {
        state.currency = currency
    }
    
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
// initial state
const state = () => ({
    apiUrl: 'https://calculator.ardi.ge/api',
    activeStep: 0,
    currency: 'gel',
    currencyCodes: {
        GEL: '₾',
        EUR: '€',
        USD: '$'
    },
    calculatedPrices: {
        premium: 0,
        payPremiumPerMonth: 0,
        deductible: 0,
        totalDeductible: 0,
        discount: 0,
        proLimit: 0,
        proPremium: 0,
        feiLimit: 0,
        feiPremium: 0,
        tplPremium: 0,
        tplLimit: 0,
        maLimit: 0,
        maPremium: 0,
        cascoDiscount: 0,
        discountWithoutCasco: 0,
        cascoNetPremium: 0,
        tariff:0
    },   
    CardCalculator: {},
})

// getters
const getters = {
    
}


// actions
const actions = {
    
}

// mutations
const mutations = {
    UpdateCalculatedPrices(state, prices) {
        state.calculatedPrices.deductible = prices.deductible;
        state.calculatedPrices.discount = prices.discount;
        state.calculatedPrices.payPremiumPerMonth = prices.payPremiumPerMonth;
        state.calculatedPrices.premium = prices.premium;
        state.calculatedPrices.totalDeductible = prices.totalDeductible;
        state.calculatedPrices.feiLimit=prices.feiLimit;
        state.calculatedPrices.feiPremium=prices.feiPremium;
        state.calculatedPrices.proLimit=prices.proLimit;
        state.calculatedPrices.proPremium=prices.proPremium;
        state.calculatedPrices.maLimit=prices.maLimit;
        state.calculatedPrices.maPremium=prices.maPremium;
        state.calculatedPrices.tplPremium=prices.tplPremium;
        state.calculatedPrices.tplLimit=prices.tplLimit;
        state.calculatedPrices.cascoDiscount = prices.cascoDiscount;
        state.calculatedPrices.discountWithoutCasco = prices.discountWithoutCasco
        state.calculatedPrices.cascoNetPremium = prices.cascoNetPremium;
        state.calculatedPrices.tariff = prices.tariff;
    },
    resetCalculatedPrices(state){
        state.calculatedPrices.deductible = 0;
        state.calculatedPrices.discount = 0;
        state.calculatedPrices.payPremiumPerMonth = 0;
        state.calculatedPrices.premium = 0;
        state.calculatedPrices.totalDeductible = 0;
        state.calculatedPrices.feiLimit = 0;
        state.calculatedPrices.feiPremium = 0;
        state.calculatedPrices.proLimit = 0;
        state.calculatedPrices.proPremium =0;
        state.calculatedPrices.maLimit = 0;
        state.calculatedPrices.maPremium = 0;
        state.calculatedPrices.tplPremium = 0;
        state.calculatedPrices.tplLimit = 0;
        state.calculatedPrices.cascoDiscount = 0;
        state.calculatedPrices.discountWithoutCasco = 0;
        state.calculatedPrices.cascoNetPremium = 0;
        state.calculatedPrices.tariff = 0;
    },
    UpdateCardCalculator(state, card) {
        state.CardCalculator = card;

    },
    updateActiveStep(state, id){
        state.activeStep = id  
    },
    updateCurrency(state, currency) {
        state.currency = currency
    }
    
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}